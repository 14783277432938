<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isFormOpen"
          width="500"
          persistent
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              :ripple="false"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <rebate-system-form
            :key="`${rebateSystem.id}-${isFormOpen}`"
            :rebate-system="rebateSystem"
            :rebate-system-units="rebateSystemUnits"
            :users="users"
            :is-submitting="isSubmitting"
            @submit="submit($event)"
            @close="isFormOpen = false; rebateSystem = {}"
          />
        </v-dialog>
      </div>

      <v-data-table
        ref="dataTable"
        :key="`data-table-${rebateSystems.length}`"
        :headers="headers"
        :items="rebateSystems"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        :expanded="rebateSystems"
        sort-by="attributes.bootTimeAt"
        sort-desc
        no-data-text="データがありません"
      >
        <template #[`item.attributes.bootTimeAt`]="{ item }">
          {{ new Date(item.attributes.bootTimeAt).toLocaleString('ja-JP').split(/:\d{1,2}$/)[0] }}
        </template>

        <template #[`item.actions`]="{item}">
          <span class="d-flex align-center justify-end">
            <v-btn
              icon
              :ripple="false"
              @click="
                rebateSystem = item;
                isFormOpen = true"
            >
              <v-icon small>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>

            <yona-edit-dialog
              title="削除の確認"
              btn-color="error"
              save-text="同意の上で削除"
              @save="destroy(item.id)"
            >
              <template #append-outer-display-name>
                <v-btn
                  icon
                  :ripple="false"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </template>

              <template #input>
                <span>
                  削除を実行してもよろしいですか
                </span>
              </template>
            </yona-edit-dialog>
          </span>
        </template>

        <template #expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col
                cols="12"
                class="ps-4"
              >
                <v-treeview
                  :items="[{
                    id: JSON.stringify(item.attributes.rebateSystemUnitOrchestrations.data),
                    name: `構成ユニット(${item.attributes.rebateSystemUnitOrchestrations.data.length})`,
                    children: [{}],
                  }]"
                >
                  <template #label="{ item: node }">
                    <div
                      v-if="!node.name"
                      class="d-flex flex-wrap"
                      style="white-space: normal"
                    >
                      <div
                        v-for="(unit, unitIdx) in getSystemUnits(item)"
                        :key="`rebateSystem-${item.id}-users[${unitIdx}]`"
                        class="mr-4 mb-4"
                      >
                        <v-chip>
                          {{ unit.attributes.name }}
                        </v-chip>

                        <div class="ms-3 mt-1">
                          <small class="secondary--text">ユニット別基準日時</small>

                          <yona-edit-dialog
                            :display-name="lastCalcAtDisplayName(item, unit.id)"
                            class="py-2"
                            :is-valid="!hasError"
                            @open="buildLastCalcAtForm(item, unit.id)"
                            @save="submit(lastCalcAtForm)"
                            @close="lastCalcAtForm = {}"
                          >
                            <template #input>
                              <div
                                v-if="lastCalcAtForm.id"
                                class="pt-4"
                              >
                                <v-text-field
                                  ref="lastCalcAtInput"
                                  v-model="lastCalcAtForm.rebateSystemUnitOrchestrationsAttributes[0].lastCalcAt"
                                  type="datetime-local"
                                  :min="item.attributes.bootTimeAt"
                                  :rules="[
                                    () => {
                                      if (lastCalcAtForm.rebateSystemUnitOrchestrationsAttributes[0].lastCalcAt === null) return true

                                      if (dateTimeForJsFriendly(item.attributes.bootTimeAt.split(' ').join('T')) > dateTimeForJsFriendly(lastCalcAtForm.rebateSystemUnitOrchestrationsAttributes[0].lastCalcAt.split(' ').join('T'))) {
                                        return 'ユニット別基準日時はシステム基準日時より後の日時を指定してください';
                                      }

                                      return true
                                    },
                                  ]"
                                  class="pa-0 ma-0"
                                >
                                  <template #append-outer>
                                    <v-btn
                                      v-show="lastCalcAtForm.rebateSystemUnitOrchestrationsAttributes[0].lastCalcAt"
                                      :ripple="false"
                                      color="secondary"
                                      small
                                      @click="lastCalcAtForm.rebateSystemUnitOrchestrationsAttributes[0].lastCalcAt = null"
                                    >
                                      リセット
                                    </v-btn>
                                  </template>
                                </v-text-field>
                              </div>
                            </template>
                          </yona-edit-dialog>
                        </div>
                      </div>
                    </div>

                    <span v-else>{{ node.name }}</span>
                  </template>
                </v-treeview>
              </v-col>

              <v-col
                cols="12"
                class="ps-4"
              >
                <v-treeview
                  :items="[{
                    id: JSON.stringify(item.attributes.rebateSystemUsers.data),
                    name: `所属ユーザー(${item.attributes.rebateSystemUsers.data.length})`,
                    children: [{}],
                  }]"
                >
                  <template #label="{ item: node }">
                    <v-chip-group
                      v-if="!node.name"
                      column
                    >
                      <v-chip
                        v-for="(user, userIdx) in getSystemUsers(item)"
                        :key="`rebateSystem-${item.id}-users[${userIdx}]`"
                      >
                        <v-avatar left>
                          <v-img
                            v-if="user.attributes.profileImage"
                            :src="user.attributes.profileImage"
                          />
                          <v-icon v-else>
                            {{ icons.mdiAccountCircleOutline }}
                          </v-icon>
                        </v-avatar>

                        {{ user.attributes.name }}
                      </v-chip>
                    </v-chip-group>

                    <span v-else>{{ node.name }}</span>
                  </template>
                </v-treeview>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import {
  mdiPlus,
  mdiClose,
  mdiAccountCircleOutline,
  mdiPencilOutline,
  mdiTrashCanOutline,
} from '@mdi/js'
import {
  findIndex,
  map,
  find,
  flatMap,
} from 'lodash'
import RebateSystemApi from '@/api/admin/RebateSystem'
import RebateSystemUnitApi from '@/api/admin/RebateSystemUnit'
import UserApi from '@/api/waiter/User'
import useCompInit from '@/views/composable/useCompInit'
import useDateFormat from '@/views/composable/useDateFormat'
import RebateSystemForm from '@/views/components/rebate_system/Form.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    RebateSystemForm,
    YonaEditDialog,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const { dateTimeForJsFriendly } = useDateFormat()
    const isFormOpen = ref(false)
    const isSubmitting = ref(false)
    const rebateSystem = ref({})
    const rebateSystems = ref([])
    const rebateSystemUnits = ref([])
    const users = ref([])
    const lastCalcAtForm = ref({})
    const lastCalcAtInput = ref(null)

    const hasError = computed(() => {
      return lastCalcAtInput.value && lastCalcAtInput.value[0] && lastCalcAtInput.value[0].hasError
    })

    const getSystemUnits = item => {
      return map(item.attributes.rebateSystemUnitOrchestrations.data, o => {
        return find(rebateSystemUnits.value, unit => +unit.id === +o.attributes.rebateSystemUnitId)
      })
    }

    const getSystemUsers = item => {
      return flatMap(item.attributes.rebateSystemUsers.data, o => {
        return find(users.value, user => +user.id === +o.attributes.userId) || []
      })
    }

    const getSystemUnitOrchestration = (rebateSystemUnitOrchestrations, unitId) => {
      return find(rebateSystemUnitOrchestrations, o => +o.attributes.rebateSystemUnitId === +unitId)
    }

    const buildLastCalcAtForm = (systemItem, unitId) => {
      const targetOrchestration = getSystemUnitOrchestration(systemItem.attributes.rebateSystemUnitOrchestrations.data, unitId)

      lastCalcAtForm.value = {
        id: systemItem.id,
        rebateSystemUnitOrchestrationsAttributes: [{
          id: targetOrchestration?.id,
          lastCalcAt: dateTimeForJsFriendly(targetOrchestration?.attributes?.lastCalcAt || systemItem.attributes.bootTimeAt),
        }],
      }
    }

    const lastCalcAtDisplayName = (systemItem, unitId) => {
      const targetOrchestration = getSystemUnitOrchestration(systemItem.attributes.rebateSystemUnitOrchestrations.data, unitId)

      return targetOrchestration?.attributes?.lastCalcAt
        ? new Date(targetOrchestration?.attributes?.lastCalcAt).toLocaleString('ja-JP').split(/:\d{1,2}$/)[0]
        : 'なし'
    }

    const submit = async form => {
      isSubmitting.value = true
      const isUpdate = !!form.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        name,
        description,
        bootTimeAt,
        rebateSystemUsersAttributes,
        rebateSystemUnitOrchestrationsAttributes,
      } = form

      const res = await RebateSystemApi[`${fnVerb}RebateSystem`]({
        id,
        name,
        description,
        bootTimeAt,
        rebateSystemUsersAttributes,
        rebateSystemUnitOrchestrationsAttributes,
      })

      if (res?.data) {
        const rebateSystemIdx = findIndex(rebateSystems.value, o => +o.id === +res.data.rebateSystem.data.id)
        if (isUpdate) {
          rebateSystems.value.splice(rebateSystemIdx, 1, res.data.rebateSystem.data)
        } else {
          rebateSystems.value.unshift(res.data.rebateSystem.data)
        }

        vm.$toast.success(isUpdate ? 'バックシステムを更新しました' : 'バックシステムを追加しました')
      }

      isSubmitting.value = false
      isFormOpen.value = false
      rebateSystem.value = {}
    }

    const destroy = async id => {
      const res = await RebateSystemApi.deleteRebateSystem(id)

      if (res) {
        const rebateSystemIdx = findIndex(rebateSystems.value, o => +o.id === +id)
        rebateSystems.value.splice(rebateSystemIdx, 1)
        vm.$toast.success('バックシステムを削除しました')
      }
    }

    const getRebateSystems = async () => {
      const res = await RebateSystemApi.getRebateSystems()

      if (res?.data) rebateSystems.value = [...res.data.rebateSystems.data]
    }

    const getRebateSystemUnits = async () => {
      const res = await RebateSystemUnitApi.getRebateSystemUnits()

      if (res?.data) rebateSystemUnits.value = [...res.data.rebateSystemUnits.data]
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers(['cast', 'waiter', 'alliance'])

      if (res?.data) users.value = [...res.data.users.data]
    }

    initWith([
      getRebateSystems(),
      getRebateSystemUnits(),
      getUsers(),
    ])

    return {
      // data
      isLoading,
      isFormOpen,
      isSubmitting,
      rebateSystem,
      rebateSystems,
      rebateSystemUnits,
      lastCalcAtForm,
      lastCalcAtInput,
      users,
      headers: [
        {
          text: '名前',
          value: 'attributes.name',
          sortable: false,
        },
        {
          text: '説明',
          value: 'attributes.description',
          sortable: false,
        },
        {
          text: 'システム基準日時',
          value: 'attributes.bootTimeAt',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],

      // computed
      hasError,

      // methods
      getSystemUnits,
      getSystemUsers,
      getSystemUnitOrchestration,
      lastCalcAtDisplayName,
      buildLastCalcAtForm,
      submit,
      destroy,
      dateTimeForJsFriendly,

      icons: {
        mdiPlus,
        mdiClose,
        mdiAccountCircleOutline,
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(v-data-table__expanded__content) using ($material) {
  td {
    background-color: map-deep-get($material, 'background');
  }
}

.v-data-table__expanded__content td {
  width: 100vw;
  vertical-align: top;

  &>div:first-child {
    padding: 12px 0 32px 0;

    &>.col {
      background-color: rgba(0,0,0,.05);
    }
  }
}
</style>
